import Vue from "vue";
import Vuex from "vuex";
import Vuetify from "@/plugins/vuetify";
import { shownTableColumns } from "@/components/trade/tradeTableColumns";
import { DateTime, Duration } from "luxon";

import { LibTimeFunctions } from "@habitatenergy/lib-frontend";

import createPersistedState from "vuex-persistedstate";

const { countDownToClosure, calcSP } = LibTimeFunctions;

Vue.use(Vuex);

interface SummaryWindowsState {
  pages: boolean;
  marketTimes: boolean;
  marketExposure: boolean;
  riskMetrics: boolean;
  portfolioStatus: boolean;
  profitLoss: boolean;
}

interface AppDetailsState {
  version: string;
}

interface UserOptionsState {
  notifications: boolean;
  sound: boolean;
  darkMode: boolean;
  experimental: boolean;
}

export interface TableSettingsState {
  tradeTableColumns: Array<string>;
}

interface SavedState {
  appDetails: AppDetailsState;
  userOptions: UserOptionsState;
  tableSettings: TableSettingsState;
}

interface State {
  appDetails: AppDetailsState;
  userOptions: UserOptionsState;
  tableSettings: TableSettingsState;
  summaryWindows: SummaryWindowsState;
  drawer: boolean;

  /** The current time in UTC */
  nowUTC: DateTime;
  /** The current local time in London */
  now: DateTime;
  /** The start of the current settlement period in UTC */
  settlementPeriodStartUTC: DateTime;
  /** The start of the current settlement period in London */
  settlementPeriodStart: DateTime;

  /** ISO formatted timestamp of the next settlement period in UTC */
  startUTCNextSP: string;
  /** ISO formatted timestamp of the current settlement period in UTC */
  startUTC: string;
  /** ISO formatted timestamp of the settlement period 24 hours from now in UTC */
  startUTCPlus24: string;
}

const tableSettings = {
  namespaced: true,
  state: {
    tradeTableColumns: shownTableColumns,
  } as TableSettingsState,
  mutations: {
    updateTradeTableColumns(state: TableSettingsState, value: Array<string>) {
      state.tradeTableColumns = value;
    },
  },
};

const summaryWindows = {
  namespaced: true,
  state: {
    pages: true,
    marketTimes: true,
    marketExposure: false,
    riskMetrics: false,
    portfolioStatus: false,
    profitLoss: false,
  } as SummaryWindowsState,
  mutations: {
    toggleWindow(store, window) {
      store[window] = !store[window];
    },
  },
};

const appDetails = {
  namespaced: true,
  state: {
    version: process.env.VERSION,
  } as AppDetailsState,
};

const userOptions = {
  namespaced: true,
  state: {
    notifications: true,
    experimental: false,
    sound: true,
    darkMode: false,
    mini: true,
  } as UserOptionsState,
  mutations: {
    toggleNotifications(state) {
      state.notifications = !state.notifications;
    },
    toggleSound(state) {
      state.sound = !state.sound;
    },
    toggleExperimental(state) {
      state.experimental = !state.experimental;
    },
    toggleMini(state) {
      state.mini = !state.mini;
    },
    toggleDarkMode(state) {
      state.darkMode = !state.darkMode;
      Vuetify.framework.theme.dark = state.darkMode;
    },
  },
};

const saveState = createPersistedState({
  key: "habitat-energy-trading-ui",
  paths: ["userOptions", "appDetails", "tableSettings"],
  getState: (key: string, storage: Storage) => {
    let value;
    let state: SavedState;

    try {
      state =
        (value = storage.getItem(key)) && typeof value !== "undefined"
          ? JSON.parse(value)
          : undefined;
      if (state.appDetails.version !== process.env.VERSION) return {};
      return state;
    } catch (err) {}
  },
  rehydrated: (store: any) => {
    const state: State = store.state;
    if (state.userOptions.darkMode) Vuetify.framework.theme.dark = true;
  },
});

/** Returns the start of the current settlement period in UTC */
export function calcSettlementPeriodStartUTC(value: DateTime): DateTime {
  const minute = Math.ceil((value.minute + 1) / 30.0) * 30.0 - 30.0;
  const startTime = value.set({ minute, second: 0, millisecond: 0 });
  return startTime.toUTC();
}

export function calcStartUTCNextSP(value: DateTime): string {
  const minute = Math.floor((value.minute + 1) / 30.0) * 30.0 + 30.0;
  const startTime = value.set({ minute, second: 0, millisecond: 0 });
  return startTime.toUTC().toISO();
}

export function calcStartUTC(value: DateTime): string {
  const minute = Math.ceil((value.minute + 1) / 30.0) * 30.0 - 30.0;
  const startTime = value.set({ minute, second: 0, millisecond: 0 });
  return startTime.toUTC().toISO();
}

function calcStartUTCPlus24(value: DateTime): string {
  const dur = Duration.fromObject({ days: 1 });
  const minute = Math.ceil(value.minute / 30.0) * 30.0 - 30.0;
  const startTime = value.set({ minute, second: 0, millisecond: 0 }).plus(dur);
  return startTime.toUTC().toISO();
}

export default new Vuex.Store({
  modules: {
    summaryWindows,
    userOptions,
    appDetails,
    tableSettings,
  },
  state: {
    drawer: false,
    nowUTC: DateTime.now(),
    now: DateTime.local().setZone("Europe/London"),
    startUTC: calcStartUTC(DateTime.local().setZone("Europe/London")),
    startUTCNextSP: calcStartUTCNextSP(DateTime.local().setZone("Europe/London")),
    startUTCPlus24: calcStartUTCPlus24(DateTime.local().setZone("Europe/London")),
  } as State,
  mutations: {
    updateDraw(state: State, value: boolean) {
      state.drawer = value;
    },
    updateTime(state: State) {
      state.nowUTC = DateTime.now();
      state.now = state.nowUTC.setZone("Europe/London");
      const settlementPeriodStartUTC = calcSettlementPeriodStartUTC(state.nowUTC);
      if (settlementPeriodStartUTC != state.settlementPeriodStartUTC) {
        // The current settlement period has changed.
        state.settlementPeriodStartUTC = settlementPeriodStartUTC;
        state.settlementPeriodStart = settlementPeriodStartUTC.setZone("Europe/London");
        // Update legacy state attributes
        state.startUTC = calcStartUTC(state.now);
        state.startUTCNextSP = calcStartUTCNextSP(state.now);
        state.startUTCPlus24 = calcStartUTCPlus24(state.now);
      }
    },
  },
  getters: {
    /** Returns the current settlement period number, e.g. 36 */
    currentSettlementPeriod(store: State): number {
      return calcSP(store.settlementPeriodStart);
    },
    /** Returns a human readable duration until the start of the next settlement period */
    nextSettlementPeriod(store: State) {
      const minute = Math.floor(store.now.minute / 30.0) * 30.0 + 30.0;
      const endTime = store.now.set({ minute, second: 0, millisecond: 0 });
      return countDownToClosure(store.now, endTime.toObject());
    },
    /** Returns a human readable duration until the intraday market closure */
    nextIntradayClosure(store: State) {
      const minute = Math.floor(store.now.minute / 30.0) * 30.0 + 15.0;
      const endTime = store.now.set({ minute, second: 0, millisecond: 0 });
      return countDownToClosure(store.now, endTime.toObject());
    },
    /** Returns a human readable duration until the day ahead market closure */
    dAClosure(store: State) {
      return countDownToClosure(store.now, {
        hour: 9,
        minute: 20,
        second: 0,
        millisecond: 0,
      });
    },
    /** Returns a human readable duration until the day ahead half hourly market closure */
    dAHHClosure(store: State) {
      return countDownToClosure(store.now, {
        hour: 15,
        minute: 30,
        second: 0,
        millisecond: 0,
      });
    },
    /** Returns an array of the start time of the next 24 settlement periods in London including the current period */
    settlementPeriods(store: State): DateTime[] {
      const dur = Duration.fromObject({ minutes: 30 });
      const firstSP = store.settlementPeriodStart;
      let currentPeriod = firstSP;
      const periods = [firstSP];
      for (let i = 0; i < 24; i++) {
        currentPeriod = currentPeriod.plus(dur);
        periods.push(currentPeriod);
      }
      return periods;
    },
  },
  actions: {
    startClock({ commit }) {
      setInterval(() => {
        commit("updateTime");
      }, 1000);
    },
  },
  plugins: [saveState],
});
