<template>
  <v-app>
    <NavMenu v-if="$auth.isAuthenticated"></NavMenu>
    <MainAppBar v-if="$auth.isAuthenticated"></MainAppBar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import MainAppBar from "./components/general/MainAppBar.vue";
import NavMenu from "./components/general/NavMenu.vue";

export default Vue.extend({
  name: "App",
  components: {
    NavMenu,
    MainAppBar,
  },
  created() {
    this.$store.dispatch("startClock");
  },
  methods: {
    async callApi() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      console.log(token);
    },
  },
});
</script>
