import Vue from "vue";
import Vue2Filters from "vue2-filters";

const Vue2FiltersConfig = {
  capitalize: {
    onlyFirstLetter: false,
  },
  number: {
    format: "0.00",
    thousandsSeparator: ",",
    decimalSeparator: ".",
  },
  bytes: {
    decimalDigits: 2,
  },
  percent: {
    decimalDigits: 2,
    multiplier: 100,
    decimalSeparator: ".",
  },
  currency: {
    symbol: "£",
    decimalDigits: 2,
    thousandsSeparator: ",",
    decimalSeparator: ".",
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    showPlusSign: false,
  },
  pluralize: {
    includeNumber: false,
  },
  ordinal: {
    includeNumber: false,
  },
};

Vue.use(Vue2Filters, Vue2FiltersConfig);
