<template>
  <v-navigation-drawer
    app
    right
    disable-resize-watcher
    v-model="visible"
    dark
    color="#192E41"
    :mini-variant="mini"
  >
    <template v-slot:prepend v-if="!mini">
      <user-info :auth="$auth"></user-info>
    </template>
    <template v-slot:prepend v-else>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <img
            v-if="$auth.user.picture"
            :src="$auth.user.picture"
            alt="Profile"
          />
          <v-icon v-else dark>account_circle</v-icon>
        </v-list-item-avatar>
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <TradePages :nav="!mini" dense />

    <v-divider></v-divider>
    <v-list :nav="!mini" dense>
      <v-subheader>Options</v-subheader>
      <v-list-item link @click="toggleNotifications">
        <v-list-item-icon>
          <v-icon>{{ notificationIcon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ notificationText }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link @click="toggleSound">
        <v-list-item-icon>
          <v-icon>{{ soundIcon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ soundText }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link @click="toggleMini">
        <v-list-item-icon>
          <v-icon>{{ miniIcon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ miniText }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link @click="toggleDarkMode">
        <v-list-item-icon>
          <v-icon>brush</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ themeText }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list dense :nav="!mini">
      <v-subheader>Actions</v-subheader>
      <v-list-item
        v-for="action in actions"
        :key="action.url"
        @click="action.method"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ action.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ action.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pr-1">
        <div v-if="mini" :class="versionClasses" @dblclick="toggleExperimental">
          {{ version }}
        </div>
        <div v-else :class="versionClasses" @dblclick="toggleExperimental">
          Version: {{ version }}
        </div>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import { UserInfo } from "@habitatenergy/lib-frontend";
import TradePages from "./TradePages";
export default Vue.extend({
  name: "NavMenu",
  components: { TradePages, UserInfo },
  data() {
    return {
      version: process.env.VERSION,
      actions: [
        { method: this.logout, title: "Logout", icon: "power_settings_new" },
      ],
    };
  },
  computed: {
    ...mapState("userOptions", {
      notifications: (state) => state.notifications,
      sound: (state) => state.sound,
      mini: (state) => state.mini,
      darkMode: (state) => state.darkMode,
      experimental: (state) => state.experimental,
    }),
    ...mapState({
      drawer: (state) => state.drawer,
    }),
    visible: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.updateDraw(value);
      },
    },
    notificationText() {
      if (this.notifications) {
        return "Notifications enabled";
      } else {
        return "Notifications disabled";
      }
    },
    notificationIcon() {
      if (this.notifications) {
        return "notifications";
      } else {
        return "notifications_off";
      }
    },
    soundText() {
      if (this.sound) {
        return "Sound enabled";
      } else {
        return "Sound disabled";
      }
    },
    soundIcon() {
      if (this.sound) {
        return "volume_up";
      } else {
        return "volume_off";
      }
    },
    themeText() {
      if (this.darkMode) {
        return "Dark theme";
      } else {
        return "Light theme";
      }
    },
    miniText() {
      if (this.mini) {
        return "Mini navbar";
      } else {
        return "Full navbar";
      }
    },
    miniIcon() {
      if (this.mini) {
        return "menu_open";
      } else {
        return "chevron_right";
      }
    },
    versionClasses() {
      if (this.experimental) {
        return "text-right caption red--text";
      } else {
        return "text-right caption white--text";
      }
    },
  },

  methods: {
    ...mapMutations("userOptions", [
      "toggleNotifications",
      "toggleSound",
      "toggleDarkMode",
      "toggleMini",
      "toggleExperimental",
    ]),
    ...mapMutations(["updateDraw"]),
    logout() {
      this.$auth.logout({
        returnTo: `${window.location.origin}/login`,
      });
    },
  },
});
</script>

<style>
.v-navigation-drawer {
  will-change: initial;
}
</style>
