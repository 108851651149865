import { defaultDataIdFromObject } from "apollo-cache-inmemory";
export const ooCacheOptions = {
  dataIdFromObject: (object: any) => {
    switch (object.__typename) {
      case "SettlementPeriod":
        return object.start_utc;
      case "FrequencyContracts":
        return object.uuid;
      case "PortfolioTrade":
        return object.uuid;
      case "Portfolio":
        return object.uuid;
      case "PortfolioTradeVersion":
        return object.version_uuid;
      case "Status":
        return object.name;
      case "GridConnection":
        return object.uuid;
      case "TradingBlock":
        return object.slug;
      // case "bar":
      //   return `bar:${object.blah}`; // append `bar` to the `blah` field as the identifier
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
};
