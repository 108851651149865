import Vue from "vue";
import router from "./router";
import { Auth0Plugin } from "@habitatenergy/lib-frontend";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "./plugins/fontawesome";
import "./plugins/vue2filters";
import "./plugins/vjsf";
import VueClipboard from "vue-clipboard2";
import VueMask from "v-mask";
import { createProvider } from "./vue-apollo-utils";

import store from "./store";
import JsonCSV from "vue-json-csv";

// Removing existing service workers
navigator.serviceWorker.getRegistrations().then(function (registrations) {
  for (let registration of registrations) {
    registration.unregister()
  }
});

Vue.config.productionTip = false;

Vue.use(VueMask);
Vue.use(VueClipboard);
Vue.component("downloadCsv", JsonCSV);

const domain = config.auth0_domain;
const clientId = config.auth0_clientId;
const audience = config.auth0_audience;

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

const shared_router_query_params = ["MeterData", "ReportingVLPManagement"];

router.beforeEach((to, from, next) => {
  const to_name = to.name || "";
  const from_name = from.name || "";
  if (
    !hasQueryParams(to) &&
    hasQueryParams(from) &&
    shared_router_query_params.includes(to_name) &&
    shared_router_query_params.includes(from_name)
  ) {
    next({ name: to.name || "", query: from.query });
  } else {
    next()
  }
})

new Vue({
  router,
  apolloProvider: createProvider(),
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
