export const headers = [
  { text: "ID", value: "shortUUID" },
  {
    text: "UK Start",
    value: "ukStart",
    sortable: false,
  },
  {
    text: "SP / Block",
    value: "settlementPeriodOrBlock",
    sort: (a, b) =>
      a.firstSPUTC < b.firstSPUTC ? -1 : a.firstSPUTC > b.firstSPUTC ? 1 : 0,
  },
  {
    text: "Exposure [MW]",
    value: "exposure",
  },
  { text: "Portfolio", value: "portfolio" },
  {
    text: "Author",
    value: "author",
    sort: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
  },
  { text: "Total power [MW]", value: "power", align: "center" },
  { text: "Spec [MW]", value: "specPower", align: "center" },
  { text: "Asset [MW]", value: "assetPower", align: "center" },
  { text: "Max buy [£/MWh]", value: "maxBuy", align: "center" },
  { text: "Min sell [£/MWh]", value: "minSell", align: "center" },
  { text: "Price [£/MWh]", value: "price", align: "center" },
  { text: "Status", value: "status" },
  { text: "Valid util", value: "validUtil" },
  { text: "Action", value: "action", sortable: false },
];

export const tableColumns = [
  ...headers.map((header) => {
    return header.text;
  }),
];

export const shownTableColumns = [...tableColumns];
