<template>
  <v-list :nav="nav" :dense="dense">
    <div
      v-for="section in [
        { id: 'Pins', data: shortcuts },
        { id: 'Pages', data: pages },
        { id: 'Old', data: old },
      ]"
      :key="section.id"
    >
      <v-divider v-if="section.id !== 'Pins'"></v-divider>
      <v-subheader>
        {{ section.id }}
      </v-subheader>
      <div v-for="page in section.data" :key="page.to">
        <v-list-item
          :to="{ name: page.to }"
          :color="colour"
          link
          exact
          v-if="!page.children || !dense || !nav"
        >
          <v-tooltip left v-if="!nav">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon style="opacity: 0.9" v-bind="attrs" v-on="on">
                <v-icon> {{ page.icon }} </v-icon>
              </v-list-item-icon>
            </template>
            <span>{{ page.title }}</span>
          </v-tooltip>
          <v-list-item-icon style="opacity: 0.9" v-else>
            <v-icon> {{ page.icon }} </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ page.title }}</v-list-item-title>
            <v-list-item-subtitle v-if="showSummary">
              {{ page.text }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-group v-else no-action :color="colour">
          <template v-slot:activator>
            <v-list-item-icon style="opacity: 0.7">
              <v-icon> {{ page.icon }} </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ page.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in page.children"
            :key="child.to"
            link
            dark
            :to="{ name: child.to }"
          >
            <v-list-item-title :class="child.colour">{{
              child.title
            }}</v-list-item-title>
            <v-list-item-subtitle v-if="showSummary">
              {{ child.text }}
            </v-list-item-subtitle>
            <v-list-item-icon v-if="child.icon">
              <v-icon small> {{ child.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </div>
    </div>
  </v-list>
</template>
<script lang="ts">
import { marketData, MarketInfo } from "@/views/tradeManagement/marketInfo";
import Vue from "vue";
import { toObj } from "@/lib";

interface MarketObj {
  [name: string]: MarketInfo;
}
const marketDatObj: MarketObj = toObj(marketData, "name");

export default Vue.extend({
  name: "TradePages",
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
    nav: {
      type: Boolean,
      default: true,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    colour: {
      type: String,
      default: "#00E888",
    },
  },
  data() {
    return {
      shortcuts: [
        {
          to: "TradeSummary",
          title: "Summary",
          text: "Trade UI summary page",
          icon: "home",
        },
        {
          to: "DispatchRoot",
          title: "Dispatch",
          text: "View and edit asset dispatches",
          icon: "ev_station",
        },
        {
          to: "TransmissionRoot",
          title: "Transmission",
          text: "View / Send commands to Transmission capable Assets",
          icon: "electrical_services",
        },
        {
          to: "OperationsOutages",
          title: "Outages",
          text: "View and edit current / future and past outages",
          icon: "report_problem",
        },
      ],
      pages: [
        {
          to: "OperationsRoot",
          title: "Operations",
          text: "Outage management and dispatch",
          icon: "construction",
          children: [
            {
              to: "DispatchRoot",
              title: "Dispatch",
              text: "View and edit asset dispatches",
              icon: "ev_station",
            },
            {
              to: "TransmissionRoot",
              title: "Transmission",
              text: "View / Send commands to Transmission capable Assets",
              icon: "electrical_services",
            },
            {
              to: "OperationsOutages",
              title: "Outages",
              text: "View and edit current / future and past outages",
              icon: "report_problem",
            },
            {
              to: "DFROutagesRoot",
              title: "DFR Outages",
              text: "Submit DFR outages",
              icon: "announcement",
            },
            {
              to: "PeakersRoot",
              title: "Peakers",
              text: "Access the peakers dashboard",
            },
          ],
        },
        {
          to: "FrequencyContractsRoot",
          title: "Frequency Contracts",
          text: "View and manage battery frequency contracts",
          icon: "ssid_chart",
          children: [
            {
              to: "FrequencyContractsCurrent",
              title: "Current contracts",
              text: "Current active frequency contracts",
              icon: "table_view",
              colour: undefined,
            },
            {
              to: "FrequencyContractsToday",
              title: "Today",
              text: "Frequency contracts today for the batteries / sites",
              icon: "calendar_today",
              colour: undefined,
            },
            {
              to: "FrequencyContractsTomorrow",
              title: "Tomorrow",
              text: "Frequency contracts tomorrow for the batteries / sites",
              icon: "calendar_today",
              colour: undefined,
            },
            {
              to: "FrequencyContractsSchedule",
              title: "Schedule",
              text: "Weekly contract schedule for each asset",
              icon: "event",
              colour: undefined,
            },
            {
              to: "FrequencyContractsHistory",
              title: "Contracts history",
              text: "Frequency contracts history view",
              icon: "history",
              colour: undefined,
            },
          ],
        },
        {
          to: "ReportingRoot",
          title: "Reporting",
          text: "Trade reporting pages",
          icon: "book",
          children: [
            {
              to: "ReportingMarketOrders",
              title: "Market orders",
              text: "Match market trades with portfolio trades",
              icon: "link",
              colour: undefined,
            },
            {
              to: "ReportingDFR",
              title: "DFR files",
              text: "View and manage dynamic frequency response performance files",
              icon: "data_exploration",
              colour: undefined,
            },
            {
              to: "ReportingFileUpload",
              title: "Reports upload",
              text: "Upload daily reports and invoices to client portal",
              icon: "upload_file",
              colour: undefined,
            },
          ],
        },
        {
          to: "TradeHistoryRoot",
          title: "Trade History",
          text: "Browse the trades that have been made or suggested",
          icon: "history",
          children: [
            {
              to: "TradeHistoryPortfolio",
              title: "Portfolio Trades",
              text: "Query portfolio trades",
              icon: "assignment",
              colour: undefined,
            },
          ],
        },
        {
          to: "ConfigRoot",
          title: "Config",
          text: "Parameters and operational config",
          icon: "settings",
          children: [
            {
              to: "ParametersRoot",
              title: "Master parameters",
              text: "Config Parameter such as users, companies, battery, grid connection etc",
              icon: "admin_panel_settings",
              colour: undefined,
            },
            {
              to: "DFRConfig",
              title: "DFR config",
              text: "Configure the dynamic frequency parameters for the batteries",
              icon: "query_stats",
              colour: undefined,
            },
            {
              to: "DispatcherConfig",
              title: "Dispatcher config",
              text: "Configure the status of the dispatcher for different batteries",
              icon: "send_time_extension",
              colour: undefined,
            },
          ],
        },
      ],
      old: [
        {
          to: "TradeManagementRoot",
          title: "Trade Management",
          text: "Enter trades and see optimizer suggested trades",
          icon: "summarize",
          children: [
            {
              to: marketDatObj["DAM"].route,
              title: marketDatObj["DAM"].name,
              text: marketDatObj["DAM"].subText,
              icon: null,
              colour: marketDatObj["DAM"].colourClass,
            },
            {
              to: marketDatObj["DHH"].route,
              title: marketDatObj["DHH"].name,
              text: marketDatObj["DHH"].subText,
              icon: null,
              colour: marketDatObj["DHH"].colourClass,
            },
            {
              to: marketDatObj["INT"].route,
              title: marketDatObj["INT"].name,
              text: marketDatObj["INT"].subText,
              icon: null,
              colour: marketDatObj["INT"].colourClass,
            },
            {
              to: marketDatObj["IPR"].route,
              title: marketDatObj["IPR"].name,
              text: marketDatObj["IPR"].subText,
              icon: null,
              colour: marketDatObj["IPR"].colourClass,
            },
          ],
        },
        {
          to: "TradeExposureRoot",
          title: "Trade Exposure",
          text: "Trade exposure for portfolio or assets",
          icon: "exposure",
          children: [
            {
              to: "TradeExposurePortfolio",
              title: "Portfolio / Global Exposure",
              text: "Trade exposure by portfolio or global Habitat exposure",
              icon: "assignment",
              colour: undefined,
            },
          ],
        },
      ],
    };
  },
});
</script>
