<template>
  <v-chip
    href="https://operations.habitatenergy.online/"
    class="ml-5"
    :color="currentColour"
  >
    <v-icon left>{{ currentIcon }}</v-icon>
    {{ currentText }}
  </v-chip>
</template>

<script>
export default {
  name: "OperationsStatus",
  data: () => ({
    mode: "normal",
    modeTexts: {
      normal: "Normal mode",
      safe: "Safe mode",
      manual: "Manual mode",
    },
    colours: {
      normal: "success",
      safe: "warning",
      manual: "warning",
    },
    icons: {
      normal: "play_arrow",
      safe: "pause",
      manual: "pause",
    },
  }),
  computed: {
    currentIcon() {
      return this.icons[this.mode];
    },
    currentText() {
      return this.modeTexts[this.mode];
    },
    currentColour() {
      return this.colours[this.mode];
    },
  },
};
</script>

<style scoped></style>
