export function toObj(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    const key = obj[property];
    acc[key] = obj;
    return acc;
  }, {});
}

export function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

export function groupBy2(objectArray, property, property2) {
  return objectArray.reduce(function (acc, obj) {
    const key = obj[property];
    const key2 = obj[property2];
    if (!acc[key]) {
      acc[key] = {};
    }
    if (!acc[key][key2]) {
      acc[key][key2] = [];
    }
    acc[key][key2].push(obj);
    return acc;
  }, {});
}
