export interface MarketInfo {
  name: string;
  subText: string;
  colourClass: string;
  route: string;
}

export const marketData = [
  {
    name: "FWD",
    subText: "Forward market (OTC / Toll)",
    colourClass: "teal--text",
    route: "TradeManagementFWD",
  },
  {
    name: "IPR",
    subText: "Prop Trading", // TODO Better sub text
    colourClass: "blue--text",
    route: "TradeManagementIPR",
  },
  {
    name: "DAM",
    subText: "Day ahead market (1 hour)",
    colourClass: "purple--text",
    route: "TradeManagementDA",
  },
  {
    name: "DHH",
    subText: "Day ahead market (1/2 hour)",
    colourClass: "indigo--text",
    route: "TradeManagementDAHH",
  },
  {
    name: "INT",
    subText: "Intraday market",
    colourClass: "deep-orange--text",
    route: "TradeManagementINT",
  },
] as Array<MarketInfo>;
