import Vue from "vue";
import Router, { NavigationGuardNext, Route } from "vue-router";

const TradeSummary = () =>
  import(/* webpackChunkName: "general" */ "../views/TradeSummary.vue");

const TradeHistory = () =>
  import(
    /* webpackChunkName: "trade-history" */ "../views/tradeHistory/TradeHistory.vue"
  );
const TradeHistoryRoot = () =>
  import(
    /* webpackChunkName: "trade-history" */ "@/views/tradeHistory/TradeHistoryRoot.vue"
  );
const TradeHistoryPortfolio = () =>
  import(
    /* webpackChunkName: "trade-history" */ "@/views/tradeHistory/TradeHistoryPortfolio.vue"
  );

const TradeManagement = () =>
  import(
    /* webpackChunkName: "trade-management" */ "../views/tradeManagement/TradeManagement.vue"
  );
const TradeManagementRoot = () =>
  import(
    /* webpackChunkName: "trade-management" */ "../views/tradeManagement/TradeManagementRoot.vue"
  );
const TradeManagementTrades = () =>
  import(
    /* webpackChunkName: "trade-management" */ "../views/tradeManagement/TradeManagementTrades.vue"
  );
const TradeManagementIMB = () =>
  import(
    /* webpackChunkName: "trade-management" */ "../views/tradeManagement/TradeManagementIMB.vue"
  );

const AuctionOptimizer = () =>
  import(
    /* webpackChunkName: "trade-management" */ "../views/tradeManagement/AuctionOptimizer.vue"
  );

const TradeExposureRoot = () =>
  import(
    /* webpackChunkName: "trade-exposure" */ "@/views/exposure/TradeExposureRoot.vue"
  );
const TradeExposurePortfolio = () =>
  import(
    /* webpackChunkName: "trade-exposure" */ "@/views/exposure/TradeExposurePortfolio.vue"
  );
const TradeExposure = () =>
  import(
    /* webpackChunkName: "trade-exposure" */ "@/views/exposure/TradeExposure.vue"
  );

const ReportingRoot = () =>
  import(
    /* webpackChunkName: "reporting" */ "@/views/reporting/ReportingRoot.vue"
  );
const ReportingDFR = () =>
  import(
    /* webpackChunkName: "reporting" */ "@/views/reporting/ReportingDFR.vue"
  );
const ReportingMarketOrders = () =>
  import(
    /* webpackChunkName: "reporting" */ "@/views/reporting/ReportingMarketOrders.vue"
  );

const ReportingFileUpload = () =>
  import(
    /* webpackChunkName: "reporting" */ "@/views/reporting/ReportingFileUpload.vue"
  );

const ReportingMeterData = () =>
  import(
    /* webpackChunkName: "reporting" */ "@/views/reporting/ReportingMeterData.vue"
  );

const ReportingVLPManagement = () =>
  import(
    /* webpackChunkName: "reporting" */ "@/views/reporting/ReportingVLPManagement.vue"
  );

const Reporting = () =>
  import(/* webpackChunkName: "reporting" */ "@/views/reporting/Reporting.vue");

const OperationsRoot = () =>
  import(
    /* webpackChunkName: "operations" */ "@/views/operations/OperationsRoot.vue"
  );
const Operations = () =>
  import(
    /* webpackChunkName: "operations" */ "@/views/operations/Operations.vue"
  );
const OperationsOutages = () =>
  import(
    /* webpackChunkName: "operations" */ "@/views/operations/OperationsOutages.vue"
  );
const PeakersRoot = () =>
  import(/* webpackChunkName: "data" */ "@/views/operations/PeakersRoot.vue");

const DispatchRoot = () =>
  import(/* webpackChunkName: "data" */ "@/views/operations/DispatchRoot.vue");

const DFROutagesRoot = () =>
  import(
    /* webpackChunkName: "operations" */ "@/views/operations/DFROutagesRoot.vue"
  );

const TransmissionRoot = () =>
  import(
    /* webpackChunkName: "operations" */ "@/views/operations/TransmissionRoot.vue"
  );

const FrequencyContractsSummary = () =>
  import(
    /* webpackChunkName: "frequency" */ "@/views/frequency/FrequencyContractsSummary.vue"
  );
const FrequencyContracts = () =>
  import(
    /* webpackChunkName: "frequency" */ "@/views/frequency/FrequencyContracts.vue"
  );
const FrequencyContractsRoot = () =>
  import(
    /* webpackChunkName: "frequency" */ "@/views/frequency/FrequencyContractsRoot.vue"
  );
const FrequencyContractsCurrent = () =>
  import(
    /* webpackChunkName: "frequency" */ "@/views/frequency/FrequencyContractsCurrent.vue"
  );
const FrequencyContractsHistory = () =>
  import(
    /* webpackChunkName: "frequency" */ "@/views/frequency/FrequencyContractsHistory.vue"
  );
const FrequencyContractsSchedule = () =>
  import(
    /* webpackChunkName: "frequency" */ "@/views/frequency/FrequencyContractsSchedule.vue"
  );
const FrequencyContractsToday = () =>
  import(
    /* webpackChunkName: "frequency" */ "@/views/frequency/FrequencyContractsToday.vue"
  );

const ConfigRoot = () =>
  import(/* webpackChunkName: "config" */ "@/views/config/ConfigRoot.vue");
const Config = () =>
  import(/* webpackChunkName: "config" */ "@/views/config/Config.vue");
const ParametersRoot = () =>
  import(/* webpackChunkName: "config" */ "@/views/config/ParametersRoot.vue");
const DFRConfig = () =>
  import(/* webpackChunkName: "config" */ "@/views/config/DFRConfig.vue");
const DispatcherConfig = () =>
  import(
    /* webpackChunkName: "config" */ "@/views/config/DispatcherConfig.vue"
  );

const ClockPopOut = () =>
  import(
    /* webpackChunkName: "general" */ "@/components/widgets/ClockPopOut.vue"
  );

const Login = () =>
  import(/* webpackChunkName: "general" */ "../views/general/Login.vue");
const NotFound = () =>
  import(/* webpackChunkName: "general" */ "../views/general/404.vue");

import { authGuard } from "@habitatenergy/lib-frontend";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "TradeSummary",
      component: TradeSummary,
    },
    {
      path: "/clock",
      name: "ClockPopOut",
      component: ClockPopOut,
    },
    {
      path: "/trade-history",
      component: TradeHistory,
      children: [
        {
          path: "",
          name: "TradeHistoryRoot",
          component: TradeHistoryRoot,
        },
        {
          path: "portfolio",
          name: "TradeHistoryPortfolio",
          component: TradeHistoryPortfolio,
        },
      ],
    },
    {
      path: "/trade-management",
      component: TradeManagement,
      children: [
        {
          path: "",
          name: "TradeManagementRoot",
          component: TradeManagementRoot,
        },
        {
          path: "FWD",
          name: "TradeManagementFWD",
          component: TradeManagementTrades,
          props: { market: "FWD" },
        },
        {
          path: "DA",
          name: "TradeManagementDA",
          component: TradeManagementTrades,
          props: { market: "DAM" },
        },
        {
          path: "DAHH",
          name: "TradeManagementDAHH",
          component: TradeManagementTrades,
          props: { market: "DHH" },
        },
        {
          path: "INT",
          name: "TradeManagementINT",
          component: TradeManagementTrades,
          props: { market: "INT" },
        },
        {
          path: "IPR",
          name: "TradeManagementIPR",
          component: TradeManagementTrades,
          props: { market: "IPR" },
        },
        {
          path: "IMB",
          name: "TradeManagementIMB",
          component: TradeManagementIMB,
        },
        {
          path: "auction-optimizer",
          name: "AuctionOptimizer",
          component: AuctionOptimizer,
        },
      ],
    },
    {
      path: "/trade-exposure",
      component: TradeExposure,
      children: [
        {
          path: "",
          name: "TradeExposureRoot",
          component: TradeExposureRoot,
        },
        {
          path: "portfolio",
          name: "TradeExposurePortfolio",
          component: TradeExposurePortfolio,
        },
      ],
    },
    {
      path: "/operations",
      component: Operations,
      children: [
        {
          path: "",
          name: "OperationsRoot",
          component: OperationsRoot,
        },
        {
          path: "outages",
          name: "OperationsOutages",
          component: OperationsOutages,
        },
        {
          path: "dfr_outages",
          name: "DFROutagesRoot",
          component: DFROutagesRoot,
        },
        {
          path: "transmission",
          name: "TransmissionRoot",
          component: TransmissionRoot,
        },
        {
          path: "peakers",
          name: "PeakersRoot",
          component: PeakersRoot,
        },
        {
          path: "dispatch/:gridOrBattery?",
          name: "DispatchRoot",
          component: DispatchRoot,
          props: true,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext) {
            if (
              (to.params.gridOrBattery !== "grid" &&
                to.params.gridOrBattery !== "batteries") ||
              !to.params.gridOrBattery
            ) {
              next({ name: "DispatchRoot", params: { gridOrBattery: "grid" } });
            } else {
              next();
            }
          },
        },
      ],
    },
    {
      path: "/reporting",
      component: Reporting,
      children: [
        {
          path: "",
          name: "ReportingRoot",
          component: ReportingRoot,
        },
        {
          path: "market-orders",
          name: "ReportingMarketOrders",
          component: ReportingMarketOrders,
        },
        {
          path: "dfr",
          name: "ReportingDFR",
          component: ReportingDFR,
        },
        {
          path: "file-upload",
          name: "ReportingFileUpload",
          component: ReportingFileUpload,
        },
        {
          path: "meter-data",
          name: "MeterData",
          component: ReportingMeterData,
        },
        {
          path: "vlp-management",
          name: "ReportingVLPManagement",
          component: ReportingVLPManagement,
        },
      ],
    },
    {
      path: "/config",
      component: Config,
      children: [
        {
          path: "",
          name: "ConfigRoot",
          component: ConfigRoot,
        },
        {
          path: "parameters/:dataType?",
          name: "ParametersRoot",
          component: ParametersRoot,
          props: true,
        },
        {
          path: "dff_config",
          name: "DFRConfig",
          component: DFRConfig,
        },
        {
          path: "dispatcher_config",
          name: "DispatcherConfig",
          component: DispatcherConfig,
        },
      ],
    },
    {
      path: "/frequency",
      component: FrequencyContracts,
      children: [
        {
          path: "",
          name: "FrequencyContractsRoot",
          component: FrequencyContractsRoot,
        },
        {
          path: "summary",
          name: "FrequencyContractsSummary",
          component: FrequencyContractsSummary,
        },
        {
          path: "current/:dialog?",
          name: "FrequencyContractsCurrent",
          component: FrequencyContractsCurrent,
        },
        {
          path: "schedule",
          name: "FrequencyContractsSchedule",
          component: FrequencyContractsSchedule,
        },
        {
          path: "today",
          name: "FrequencyContractsToday",
          component: FrequencyContractsToday,
        },
        {
          path: "tomorrow",
          name: "FrequencyContractsTomorrow",
          component: FrequencyContractsToday, // N.B. Uses Today view
        },
        {
          path: "history",
          name: "FrequencyContractsHistory",
          component: FrequencyContractsHistory,
        },
      ],
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/index.html",
      beforeEnter: (to, from, next) => {
        next("/");
      },
    },
    {
      path: "*",
      name: "404",
      component: NotFound,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    return next();
  }
  authGuard(to, from, next);
});

export default router;
